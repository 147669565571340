/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  scroll-behavior: smooth;
  /* --color-primary: #50add8; */
  --color-primary: #eaa157;
  --color-primary-rgb: 209, 161, 87;
  --hobitours-secondary:  #0a3454; /* Secondary color */
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  --color-links: #50add8;
  --color-links-hover: #0a3454;
  --color-green:rgb(67, 199, 80);
}

#about{
  scroll-margin-top: 120px;
}

#servicies {
  scroll-margin-top: 110px;
}

#destination {
  scroll-margin-top: 110px;
}

#offer {
  scroll-margin-top: 110px;
}

.over {
  z-index: 10;
  position: relative;
  top :50%;
  right: 50%;
}

.primary{
  color: #eaa157;
}

.pos {
  position: relative;
}

.title {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #485664;
  /* color: #ffffff; */
  padding: 1px;
  padding-bottom: 4px;
}

@media only screen and (max-width: 768px) {
  /* For tablets: */
  .car-img {width: 100%;height:300px !important}
}
@media only screen and (min-width: 768px) {
  /* For desktops: */
  .car-img {width: 100%;height:500px !important}
}

.top {
  margin-top: 108px;
}

.icon {
  font-size: 24px;
  color: #eaa157;
}

.link{
    color: #50add8;
    text-decoration: none;
    font-size: large;
}

.bg-wh-tr{
  background: rgba(255, 255, 255, 0.605);
}

.rtl{
  direction: rtl;
}

.dropdown-menu{
  max-width: fit-content;
}

.portfolio-item {
  position: relative;
  border: 1px solid white;
  overflow: hidden;
  z-index: 1;
}

.bi-check::before {
  content: "\F26E";
  color: #eaa157;
  font-size:22px
}

.flip-card {
  background-color: transparent;
  background-size: cover;
  width: 100%;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateX(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
  background-position: center center;
  background-size: cover !important;
  /* background-attachment: fixed !important; */
}

.flip-card-back {
  background-color: #2980b9;
  background-size: cover !important;
  color: white;
  transform: rotateX(180deg);
}

.flip-card-row .f-card{
  width: 25%;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.flip-card-back .link{
  /* top: -150px; */
  font-weight: bold;
  font-size: larger;
  padding:90px 0;
  margin: 0;
  background: #333a;
  height: 100%;
}

.flip-card-front p{
  color: #fff;
  position: relative;
  font-weight: bold;
  font-size: larger;
  padding:140px 0 ;
  margin: 0;
  background: #333a;
  height: 100%;
}
.nav-link{
  --bs-nav-link-hover-color:#eaa157
}
.hc:hover >.card-body>h5{
  color: #eaa157 !important;
}

/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top , .whatsapp {
  position: fixed;
  /* visibility: hidden; */
  /* opacity: 1; */
  right: 15px;
  bottom: 15px;
  z-index: 995;
  background: var(--color-primary);
  color: white;
  /* background-color: rgb(67, 199, 80); */
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.whatsapp{
  visibility: visible;
  opacity: 1;
  background: var(--color-green);
  color: #fff;
  left: 15px;
  font-size: larger;
}

.scroll-top i {
  font-size: 24px;
  color: var(--color-white);
  line-height: 0;
}

.scroll-top:hover {
  background: rgba(var(--color-primary-rgb), 0.85);
  color: var(--color-white);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}
